import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

import Hero from "../components/ResearchProjects/Hero"
import List from "../components/ResearchProjects/List"

const ResearchProjectPage = () => {
  return (
    <Layout>
      <SEO title="Research Projects" />
      <Hero />
      <List />
    </Layout>
  )
}

export default ResearchProjectPage
