import styled from "styled-components"
import { BREAKPOINT , LG} from "../../../utils/constants"

import { MainH2, MainParagraph } from "../../../utils/GlobalComponents"

export const Wrapper = styled.div`
  text-align: center;
  padding: 100px 0;
  position: relative;
  @media (max-width: ${BREAKPOINT}) {
    text-align: left;
    padding: 114px 35px 8px;
  }
`

export const Title = styled(MainH2)`
  margin: 0 auto 32px;
  max-width: none;
`

export const Paragraph = styled(MainParagraph)`
  max-width: 446px;
  margin: 0 auto;

  @media (max-width: ${LG}) {
    position: relative;
    z-index: 5;
  }

  @media (max-width: ${BREAKPOINT}) {
    max-width: none;
  }
`
