import React, { Fragment } from "react"
import { useStaticQuery } from "gatsby"
import { Wrapper } from "./style"
import Post from "./Post"
/*(sort: {order: DESC}) */

const List = () => {
  const {
    allWordpressWpResearchProject: { edges },
  } = useStaticQuery(graphql`
    query ResearchProjectList {
      allWordpressWpResearchProject {
        edges {
          node {
            title
            acf {
              research_projects_description
              research_projects_image {
                source_url
              }
            }
          }
        }
      }
    }
  `)

  return (
    <Wrapper
      data-aos="fade-up"
      /*   data-aos-anchor=".research-projects-title" */
      data-aos-duration="1000"
      data-aos-delay="1300"
    >
      {edges.map(({ node }, i) => (
        <Fragment key={i}>
          <Post node={node} postDelay={`300`} />
        </Fragment>
      ))}
    </Wrapper>
  )
}

export default List
