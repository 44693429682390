import React, { Fragment } from "react"
import { useStaticQuery } from "gatsby"
import { useMediaQuery } from "react-responsive"
import { Wrapper, Title, Paragraph } from "./style"
import Blob from "../../common/Blob/Blob"

const Hero = () => {
  const {
    allWordpressPage: { edges },
  } = useStaticQuery(graphql`
    query ResearchProjectHero {
      allWordpressPage(filter: { wordpress_id: { eq: 341 } }) {
        edges {
          node {
            title
            excerpt
          }
        }
      }
    }
  `)

  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-device-width: 991px)",
  })

  const isNotebookOrIpad = useMediaQuery({
    query: "(max-device-width: 1199px)",
  })

  return (
    <Wrapper>
      <Fragment>
        <Blob
          toTop={"50px"}
          toLeft={"-60px"}
          customWidth={isTabletOrMobileDevice ? "0" : "8.6vw"}
          customHeight={"9.3vw"}
        />

        <Blob
          toTop={"85%"}
          toRight={"26%"}
          customWidth={isTabletOrMobileDevice ? "0" : "9.3vw"}
          customHeight={"10vw"}
        />

        <Blob
          toTop={"60%"}
          toRight={"14%"}
          customWidth={isTabletOrMobileDevice ? "0" : "26vw"}
          customHeight={"28vw"}
        />

        <Fragment>
          <Title
            data-aos="fade"
            data-aos-duration="1000"
            className="research-projects-title"
          >
            Research Projects
            </Title>
          <Paragraph
            as="div"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="500"
          >
            These are the ongoing projects that LymeMIND is conducting to advance our understanding of Lyme disease mechanisms toward the development of novel therapeutics and better diagnostics.
            </Paragraph>
        </Fragment>
      </Fragment>
    </Wrapper>
  )
}

export default Hero
