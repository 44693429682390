import React, { useState, useEffect, useRef } from "react"
import { PostCard, ContentImage, TextContent, Title, Paragraph } from "./style"
import { MainTextLink } from "../../../utils/GlobalComponents"

const Post = ({ node, postDelay }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [description, setDescription] = useState("")
  const [newDescription, setNewDescription] = useState("")
  const myRef = useRef(null)
  const scrollToRef = ref => window.scrollTo(0, ref.current.offsetTop)

  useEffect(() => {
    setDescription(node.acf.research_projects_description)
    const doc = new DOMParser().parseFromString(description, "text/html")
    const el = doc.querySelectorAll("p, ul")
    setNewDescription(el)
    countParagraph()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const toggle = () => {
    setIsOpen(!isOpen)
    countParagraph()
    if (isOpen) {
      scrollToRef(myRef)
    }
  }

  const countParagraph = () => {
    if (newDescription.length > 2) {
      var desc = ""
      setDescription("")
      newDescription.forEach((p, i) => {
        if (i > 1) {
          if (!isOpen) {
            p.classList.add("hide")
          } else {
            p.classList.remove("hide")
          }
        }
        desc += p.outerHTML
      })
      setDescription(desc)
    }
  }

  return (
    <PostCard
      key={node.id}
      data-aos="fade-up"
      data-aos-duration="500"
      data-aos-offset={postDelay}
      data-aos-once="true"
    >
   
      <ContentImage data-aos="fade-up">
        <img
          src={node.acf.research_projects_image.source_url}
          alt={node.title}
        />
      </ContentImage>
      <TextContent ref={myRef} data-aos="fade-up">
        <Title data-aos="fade" data-aos-delay="50">
          <span>{node.title}</span>
        </Title>
        <Paragraph
          dangerouslySetInnerHTML={{ __html: description }}
          data-aos="fade"
          data-aos-delay="100"
        />
        {newDescription.length > 2 && (
          <MainTextLink as="button" gradient={"orange"} onClick={toggle}>
            {isOpen ? "Read Less" : "Read More"}
          </MainTextLink>
        )}
      </TextContent>
    </PostCard>
  )
}

export default Post
