import styled from "styled-components"
import { BREAKPOINT } from "../../../utils/constants";
import {
  MainH2,
  MainParagraph,
} from "../../../utils/GlobalComponents"
import BulletimageUrl from "../../../images/Bullet.svg"

export const Wrapper = styled.div`
  text-align: center;
  padding: 60px 0;
  background-color: ${props => props.theme.neutralSilver};
  @media (max-width: ${BREAKPOINT}) {
    padding: 0;
  }
`
export const PostCard = styled.div`
  max-width: 608px;
  margin: 0 auto;
  text-align: left;
  padding: 64px 0;
  border-bottom: 1px solid ${props => props.theme.borderColor};
  button {
    border: 0;
    cursor: pointer;
  }
  @media (max-width: ${BREAKPOINT}) {
    padding: 64px 16px 40px;
    max-width: none;
    border: 0;
    position: relative;
    &::after {
      content: "";
      width: calc(100% - 70px);
      border-bottom: 1px solid ${props => props.theme.borderColor};
      position: absolute;
      bottom: 0;
      left: 35px;
    }
  }
`

export const TextContent = styled.div`
  @media (max-width: ${BREAKPOINT}) {
    padding: 0 20px;
  }
`

export const ContentImage = styled.div`
  background-color: ${props => props.theme.white};
  box-shadow: ${props => props.theme.boxShadow};
  border-radius: 8px;
  padding: 34px;
  margin-bottom: 64px;
  text-align: center;
  img {
    max-width: 100%;
  }
  @media (max-width: ${BREAKPOINT}) {
    margin-bottom: 40px;
  }
`

export const Title = styled(MainH2)`
  margin: 0 auto 18px;
  max-width: none;
  position: relative;
  padding-bottom: 30px;
  @media (max-width: ${BREAKPOINT}) {
    margin-bottom: 24px;
    padding-bottom: 0;
  }
  &::after {
    content: "_";
    display: block;
    line-height: 43px;
    margin-top: -19px;
    @media (max-width: ${BREAKPOINT}) {
      margin-top: 0;
    }
  }
  span {
    color: ${props => props.theme.neutralDark};
    background: transparent;
    -webkit-text-fill-color: ${props => props.theme.neutralDark};
    font-weight: 500;
    font-size: 32px;
    line-height: 48px;
    @media (max-width: ${BREAKPOINT}) {
      line-height: 36px;
    }
  }
`


export const Paragraph = styled(MainParagraph)`
  transition: all 1s ease;
  .hide {
    display: none;
  }
  p {
    &:first-child {
      font-weight: 300;
      font-size: 24px;
      line-height: 31px;
      @media (max-width: ${BREAKPOINT}) {
        font-size: 22px;
      }
    }
  }
  ul, li {
    margin-bottom: 24px;
  }
  ul {
    font-weight: 500;
    li {
      position: relative;
      padding-left: 24px;
      &::before {
        content: "";
        background: url(${BulletimageUrl}) no-repeat;
        position: absolute;
        top: 7px;
        left: 0;
        width: 10px;
        height: 10px;
        display: block;
      }
    }
  }
`
